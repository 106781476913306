@import 'data-download.less';
@import 'energy-projections.less';
@import 'add-user-modal.less';
@import 'change-password-modal.less';
@import 'eula.less';

.modal {
  .section-title {
    h3 {
      font-size: 0.9rem;
    }  
  }

  &.overflow-visible > .modal-wrapper {
    overflow: visible
  }
}
